<template>
  <div>
    <div class="flex flex-wrap items-center justify-center p-4 mx-auto">
      <img src="@/assets/logo.png" alt="text" class="w-full px-5 text-center text-white text-bold" />
    </div>
    <div class="flex items-center justify-center pb-5">
      <div class="flex flex-wrap items-center justify-center gap-4 px-5">
        <div class="overflow-hidden border-4 border-white rounded-lg w-96 h-80 drop-shadow-xl bg-cardColor" v-for="product in products" v-bind:key="product">
          <div class="flex items-center justify-center py-3">
            <h3 class="p-3 overflow-hidden text-3xl text-white bg-black rounded-md shadow-md">
              {{ product.title }}
            </h3>
          </div>

          <div class="flex flex-col justify-center p-2 text-center text-black">
            <h3>{{ product.word }}</h3>
            <h3>{{ product.words }}</h3>
          </div>
        </div>
      </div>
      </div>
      <div class="flex items-center justify-center py-10">
      <a href="/company" class="flex p-2 text-center border-2 border-green-700 rounded-md justify-content hover:bg-hovercolor hover:text-white">
        {{ $t("Learn about our history") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardStatic",
  data() {
    return {
      products: [
        {
          title: this.$t("TRADE"),
          word: this.$t("Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance."),
          words: this.$t("This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers."),
        },
        {
          title: this.$t("BROKERAGE"),
          word: this.$t("In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance."),
          words: this.$t("For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily"),
        },
        {
          title: this.$t("APPRAISALS"),
          word: this.$t("Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned."),
          words: this.$t("Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal"),
        },
      ],
    };
  },
};
</script>
