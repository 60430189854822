<template>
  <footer class="mx-auto text-center text-gray-600 bg-gray-100 rounded">
    <div class="px-6 pt-6">
      <nav aria-label="Newsletter Subscription">
        <form action="/newsletter" method="get">
          <div
            class="grid items-center justify-center grid-cols-1 gap-4 md:grid-cols-3"
          >
            <div class="md:ml-auto md:mb-6">
              <p>
                <strong>{{ $t("Subscribe to the newsletter") }}</strong>
              </p>
            </div>
            <div class="md:mb-6">
              <input
                type="email"
                name="email"
                id="email"
                v-model="email"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-gray-300 rounded focus:border-green-600 focus:outline-none"
                :placeholder="$t('Email address')"
              />
            </div>
            <div class="mb-6 md:mr-auto">
              <button
                class="inline-block px-6 py-2 text-xs font-medium leading-tight text-green-700 uppercase transition duration-150 ease-in-out border-2 border-green-700 rounded hover:bg-black hover:bg-opacity-5 focus:outline-none"
              >
                {{ $t("Subscribe") }}
              </button>
            </div>
          </div>
        </form>
      </nav>

      <div class="flex justify-center w-full">
        <div
          class="grid grid-cols-1 m-auto space-y-8 md:space-y-0 md:space-x-10 md:grid-cols-3"
        >
          <address class="not-italic">
            <h5 class="uppercase font-bold mb-2.5 text-left">Contact</h5>
            <ul class="flex flex-col items-start justify-start space-y-4">
              <li>
                <a
                  href="https://goo.gl/maps/ZzfwmwEVEXHrkm1L8"
                  target="_blank"
                  rel="nofollow noopener"
                  class="flex space-x-2 text-gray-600 hover:text-green-600"
                >
                  <svg
                    class="flex-shrink-0 w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <p class="text-left">
                    33 Chemin de Cézanne, 13016 Marseille - France
                  </p>
                </a>
              </li>
              <li>
                <a
                  href="mailto:contact@jacquart.com?subject=Information"
                  class="flex space-x-2 text-gray-600 hover:text-green-600"
                >
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                  <p class="text-left">contact@jacquart.com</p>
                </a>
              </li>
              <li>
                <a
                  href="tel:+33320289428"
                  class="flex space-x-2 text-gray-600 hover:text-green-600"
                >
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <p class="text-left">Tel: +33 (0)3.20.28.94.28</p>
                </a>
              </li>
            </ul>
          </address>
          <div>
            <h5 class="uppercase font-bold mb-2.5 text-left">Pages</h5>
            <nav class="flex space-x-4">
              <span class="flex flex-col items-start justify-start space-y-2">
                <router-link class="hover:text-green-600" to="/buy">{{
                  $t("Buy")
                }}</router-link>
                <router-link class="hover:text-green-600" to="/sell">{{
                  $t("Sell")
                }}</router-link>
                <router-link class="hover:text-green-600" to="/auction">{{
                  $t("auction")
                }}</router-link>
              </span>
              <span class="flex flex-col items-start justify-start space-y-2">
                <router-link class="hover:text-green-600" to="/opportunities">{{
                  $t("opportunities")
                }}</router-link>
                <router-link class="hover:text-green-600" to="/company">{{
                  $t("Company")
                }}</router-link>
                <router-link class="hover:text-green-600" to="/contact">{{
                  $t("Contact")
                }}</router-link>
              </span>
            </nav>
          </div>
          <ul class="flex flex-col w-full">
            <h5 class="uppercase font-bold mb-2.5 text-left">
              {{ $t("Activity") }}
            </h5>
            <li
              class="text-left"
              v-for="(activity, index) in activitys"
              :key="'activity' + index"
            >
              <a
                class="hover:text-green-600"
                :href="`/buy?code=${activity.attributes.code
                  .trim()
                  .replace(
                    /[0-9]/g,
                    ''
                  )}&subCode=&activity=${activity.attributes.descFR.trim()}&subCategory=&search=&brand=&year=&page=0`"
              >
                {{
                  $i18n.locale == "fr"
                    ? activity.attributes.descFR
                    : activity.attributes.descEN.trim()
                    ? activity.attributes.descEN
                    : activity.attributes.descFR
                }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-center">
        <p class="w-4/5 my-8 text-center xl:w-2/5">
          Jacquart & Fils - Spécialiste en machines textiles d'occasion Depuis
          plus de 100 ans, Jacquart & Fils propose un large choix de machines
          textiles industrielles d’occasion, soigneusement révisées et testées.
          Découvrez nos équipements pour la couture, la découpe et bien plus
          encore. Livraison internationale et accompagnement personnalisé.
        </p>
      </div>
    </div>

    <div class="p-4 text-center bg-gray-200">
      © {{ currentYear }} - Jacquart & Fils
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  name: "FooterComponent",
  data() {
    return {
      email: "",
      currentYear: new Date().getFullYear(),
      activitys: [],
      isActivityOpen: false,
      inputActivity: "",
      checkedActivities: [],
      error: null,
    };
  },
  mounted() {
    this.getActivity();
  },
  methods: {
    async getActivity() {
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters: {
          $and: [
            this.$i18n.locale == "fr"
              ? { descFR: { $contains: this.inputActivity.trim() } }
              : { descEN: { $contains: this.inputActivity.trim() } },
            { code: { $contains: "00" } },
          ],
        },
      });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`
        );
        const uniqueActivity = response.data.data.filter(
          (value, index, self) => {
            return (
              self.findIndex(
                (obj) => obj.attributes.descFR === value.attributes.descFR
              ) === index
            );
          }
        );
        this.activitys = uniqueActivity;
        this.isActivityOpen = true;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
