<template>
  <header
    class="flex flex-col items-center p-8 space-y-4 bg-gray-100 lg:flex-row lg:justify-between"
  >
    <div>
      <div>
        <img
          class="rounded w-60 h-26"
          @click="methodName"
          src="../assets/logo.png"
          alt="Logo Jacquart & Fils"
        />
      </div>
      <div class="absolute top-4 right-4">
        <button
          @click="isOpen = !isOpen"
          type="button"
          class="block text-green-600 hover:text-gray-600 focus:text-gray-500 focus:outline-none lg:hidden"
        >
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path
              v-if="isOpen"
              fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
            <path
              v-if="!isOpen"
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
      </div>
    </div>
    <nav
      :class="`flex items-center justify-center lg:flex-row lg:flex flex-wrap space-x-2 space-y-4 sm:space-y-0  ${
        !isOpen && 'hidden'
      }`"
    >
      <div
        class="flex flex-wrap items-center justify-center space-x-4 lg:flex-row lg:flex"
      >
        <router-link class="hover:text-green-600" to="/buy">{{
          $t("Buy")
        }}</router-link>
        <router-link class="hover:text-green-600" to="/sell">{{
          $t("Sell")
        }}</router-link>
        <router-link class="hover:text-green-600" to="/auction">{{
          $t("auction")
        }}</router-link>
        <router-link class="hover:text-green-600" to="/opportunities">{{
          $t("opportunities")
        }}</router-link>
        <router-link class="hover:text-green-600" to="/company">{{
          $t("Company")
        }}</router-link>
        <router-link class="hover:text-green-600" to="/contact">{{
          $t("Contact")
        }}</router-link>
      </div>
      <SearchBar />
      <LocaleSwitcher />
    </nav>
  </header>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default {
  name: "Nav",
  components: {
    SearchBar: SearchBar,
    LocaleSwitcher,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    methodName() {
      return this.$router.push("/");
    },
  },
};
</script>
