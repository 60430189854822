<template>
  <main class="container flex flex-col mx-auto home">
    <Header />
    <!-- <Card /> -->
    <ButtonSearch />
    <NewsLetter />
    <CardStatic />
  </main>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import ButtonSearch from "@/components/ButtonSearch";
import NewsLetter from "@/components/NewsLetter";
import CardStatic from "@/components/CardStatic";
import useSeo from "../composables/useSeo";

export default {
  name: "Home",
  data() {
    return {
      machines: [],
    };
  },
  setup() {
    useSeo({
      title: "JACQUART - Expert en machines textiles d'occasion",
      meta: {
        description:
          "Découvrez Jacquart & Fils, votre expert en machines textiles d'occasion. Depuis plus de 100 ans, nous achetons, vendons et négocions du matériel textile de qualité pour répondre à tous vos besoins industriels.",
        "og:type": "website",
      },
      canonical: "https://jacquart.com",
    });
  },
  components: {
    Header,
    NewsLetter,
    CardStatic,
    ButtonSearch,
    // Card,
  },
};
</script>
