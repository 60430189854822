// src/useSeo.js
import { onUnmounted } from 'vue';

export default function useSeo(metaData) {
  // Gestion du titre
  if (metaData.title) {
    document.title = metaData.title;
  }

  // Gestion des meta tags
  const metaTags = [];
  const head = document.head;

  Object.entries(metaData.meta || {}).forEach(([name, content]) => {
    const metaTag = document.createElement('meta');

    if (name.startsWith('og:')) {
      metaTag.setAttribute('property', name);
    } else {
      metaTag.setAttribute('name', name);
    }

    metaTag.setAttribute('content', content);
    head.appendChild(metaTag);
    metaTags.push(metaTag);
  });

  // Ajout de la balise canonique
  if (metaData.canonical) {
    const canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', metaData.canonical);
    head.appendChild(canonicalLink);
    metaTags.push(canonicalLink);
  }

  // Nettoyage des balises
  onUnmounted(() => {
    metaTags.forEach((tag) => head.removeChild(tag));
    if (!metaData.title) document.title = 'Jacquart & Fils';
  });
}
