<template>
  <select
    v-model="selectedLang"
    class="block m-0 text-base font-normal text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid rounded w-fit bg-clip-padding focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
    style="width: fit-content"
  >
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale }}
    </option>
  </select>
</template>

<script>
export default {
  name: "LocaleSwitcher",

  data() {
    return {
      locales: ["fr", "en"],
      selectedLang: this.$i18n.locale,
    };
  },

  watch: {
    selectedLang() {
      this.setLang();
    },
  },

  methods: {
    setLang() {
      this.$i18n.locale = this.selectedLang;
      localStorage.setItem("selectedLang", this.selectedLang);
    },
  },
};
</script>
