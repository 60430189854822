<template>
  <div class="px-10">
    <div
      class="flex flex-wrap items-center justify-center mx-auto rounded md:max-w-4xl bg-cardColor"
    >
      <div class="flex flex-wrap justify-center px-10 py-5 text-center">
        <h4 class="p-2 text-3xl text-white bg-black rounded">
          {{ $t("STAY TUNED") }}
        </h4>
      </div>
      <div>
        <p class="flex items-center justify-center px-5">
          {{
            $t(
              "Subscribe to our newsletter and receive new offers from our machine catalog. You can activate notifications on a specific category."
            )
          }}
        </p>
      </div>
      <form action="/newsletter" method="get">
        <div class="flex items-center justify-center px-5">
          <input
            type="email"
            placeholder="Email :"
            name="email"
            v-model="email"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
          />
        </div>
        <div class="flex items-center justify-center py-2">
          <button
            class="px-6 py-2 text-xs font-medium leading-tight text-green-700 uppercase transition duration-150 ease-in-out border-2 border-green-700 rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
          >
            {{ $t("Subscribe to this newsletter") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsLetter",

  data() {
    return {
      email: "",
    };
  },
};
</script>
